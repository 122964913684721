/** * 解决方案  */
<template>
  <v-app>
    <v-banner
      single-line
      height="400"
      class="banner"
    >
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/assistantAccount-Marketing-banner.png"
        height="100%"
        class="d-flex align-center"
        >
          <div class="banner-box">
            <p class="banner-title">营销应用</p>
            <p class="banner-text">活跃用户，增长裂变，都玩的转</p>
          </div>
      </v-img>
    </v-banner>
    

    <!-- 动画也太酷了吧 -->
    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat color="transparent">
        <div class="public-title">
          <p>DIVERSE SCENES</p>
          <div>
            <span>多种应用适合多样场景</span>
            <span>针对不同的营销场景提供全域解决方案</span>
          </div>
        </div>

        <div class="d-flex justify-center pt-5" style="width:900px;margin:0 auto">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2">抽奖助手</v-card-title>
                <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 5px;">粉丝活跃方案</v-card-text>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">上百款AR互动营销活动样板，提高粉丝活跃度；自主配置奖品、兑换方式及奖品获取规则即可快速开展获取粉丝的活动</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                   width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/assistantAccount-Marketing-img1.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="d-flex justify-center" style="width:900px; margin:0 auto">
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                  width="400" max-width="400" max-height="260" min-height="260"
                  src="https://h5.ophyer.cn/official_website/other/assistantAccount-Marketing-img2.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <div style="margin-left: 80px;width: 385px;"
                class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2">模版群发</v-card-title>
                <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 5px;">打破微信限制机制</v-card-text>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">公众号提供三大类型的群发（个性化群发、客服消息群发、模版群发）支持创建最少2人最多100万人的群发任务</div>
                </v-card-text>
              </div>
            </div>
          </transition>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="d-flex justify-center" style="width:900px; margin:0 auto">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center" >
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2">口令兑换</v-card-title>
                <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 5px;">编码兑奖系统</v-card-text>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">系统支持口令兑换工具，在控制台简单设置活动口令及对应奖励即可开展活动；简单灵活的奖励机制，轻松应对不同业务场景，刺激消费，增加</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                   width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/assistantAccount-Marketing-img3.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="d-flex justify-center" style="width:900px; margin:0 auto">
          <transition name="slide-fade" appear>
            <v-img style="background-color: transparent"
                  width="400" max-width="400" max-height="260" min-height="260"
                  src="https://h5.ophyer.cn/official_website/other/assistantAccount-Marketing-img4.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <div style="margin-left: 80px;width: 385px;"
                class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2">自动标签</v-card-title>
                <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 5px;">构建用户画像，实现精细化运营</v-card-text>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">自动标签帮助公众号建立粉丝画像，可根据粉丝的性别、地域、语言及关注来源自动打标签，为精细化运营提供数据支持</div>
                </v-card-text>
              </div>
            </div>
          </transition>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center width-per-100 pb-15">
      <v-card class="max-width-1200 width-per-100" flat>
        <div class="d-flex justify-center" style="width:900px; margin:0 auto">
          <transition name="slide-fade" appear>
            <div style="margin-right: 80px;width: 385px;"
                 class="d-flex align-center">
              <div>
                <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 2">多维度粉丝管理系统</v-card-title>
                <v-card-text class="pa-0 font-size-16 font-weight-400 white-space-pre-wrap" style="line-height: 1.2;margin-top: 5px;">粉丝管理方案</v-card-text>
                <v-card-text class="pa-0 mt-4 font-size-14 color-666">
                  <div class="width-per-100 white-space-pre-wrap">公众号管理系统帮助公众号建立独有的数据库，识别粉丝行为，并根据粉丝的标签及基础信息进行15个纬度的筛选、管理</div>
                </v-card-text>
              </div>
            </div>
          </transition>
          <transition name="slide-fade-reverse" appear>
            <v-img style="background-color: transparent"
                    width="400" max-width="400" max-height="260" min-height="260"
                   src="https://h5.ophyer.cn/official_website/other/assistantAccount-Marketing-img5.png">
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      size="80"
                      width="8"
                      color="grey lighten-3"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </transition>
        </div>
      </v-card>
    </div>


  </v-app>
</template>

<script>

export default {
  name: 'Solution',
  components: {
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    color: #fff;
    text-align: center;
    .banner-title {
      font-size: 48px;
      font-weight: 400;
      line-height: 53px;
    }
    .banner-text {
      margin-top: 15px;
      font-size: 18px;
      line-height: 18px;
    }
  }
  
}
</style>
